<template>
    <div class="header">
        <div class="logo">
            <img src="../assets/logo.png" />
        </div>
        <ul class="nav">
            <li
                :class="active === index ? 'active' : ''"
                v-for="(item, index) in navs"
                :key="index"
                @click="routeGo(index, item.path)"
                @mouseenter="douyinShow(index)"
                @mouseleave="douyinHide(index)"
                :id="'nav_' + index"
            >
                <span>{{ item.title }}</span>
                <img id="douyin_layer" v-if="index == 6" src="../assets/douyin.png" v-show="show" />
            </li>
        </ul>
        <div class="icons">
            <span class="location"></span>
            <span class="phone"></span>
            <span class="email"></span>
        </div>
    </div>
    <div class="content">
        <router-view></router-view>
    </div>
    <div
        class="footer"
    >© COPYRIGHT © 2021.SHANGHAIYOULIAOAD ALL RIGHTS RESERVED.沪ICP备2 Shanghai Youliao Advertising Co.,Ltd</div>
</template>

<script>
import { ref } from 'vue';
import router from '../router/index';
export default {
    setup() {
        const navs = ref([
            { title: '首页', path: '/' },
            // { title: '关于有料', path: '/about' },
            { title: '服务品牌', path: '/service-brand' },
            { title: '服务案例', path: '/service-case' },
            { title: '企业洞察', path: '/business-insight' },
            { title: '团队优势', path: '/team' },
            { title: '联系我们', path: '/contact-us' },
        ]);
        const active = ref(0);
        const routeGo = (index, path) => {
            active.value = index;
            // 路由跳转
            router.push(path);

        }

        const show = ref(false);

        const douyinShow = (index) => {
            if (index == 6 && active.value != 3) {
                show.value = true;
            }

        }

        const douyinHide = (index) => {
            if (index == 6) {
                show.value = false;
            }

        }

        return {
            navs,
            active,
            routeGo,
            show,
            douyinShow,
            douyinHide
        };
    },
}
</script>

<style scoped>
#douyin_layer {
    position: absolute;
    top: 50px;
    left: -106px;
    width: 300px;
    z-index: 999;
}

#nav_6 {
    position: relative;
}
.header {
    width: 1200px;
    height: 100px;
    background-color: #2e58e9;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 50px;
    box-sizing: border-box;
    /* margin-top: 5px; */
}
.header .logo {
    flex-basis: 180px;
    order: 1;
}
.header .logo img {
    width: 200px;
}

.nav {
    list-style: none;
    margin-left: 30px;
    flex: 1;
    order: 2;
}
.nav li {
    float: left;
    padding: 10px 20px;
    margin: 0 5px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}
.nav li:hover {
    border-bottom: 2px solid red;
}
.nav .active {
    border-bottom: 2px solid red;
}
.icons {
    flex-basis: 120px;
    list-style: none;
    order: 3;
    display: flex;
    justify-content: space-between;
}
.icons span {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.icons .location {
    background: url("../assets/icon-location.png") no-repeat center center;
    background-size: 14px;
}
.icons .phone {
    background: url("../assets/icon-phone.png") no-repeat center center;
    background-size: 14px;
}
.icons .email {
    background: url("../assets/icon-email.png") no-repeat center center;
    background-size: 14px;
}

.content {
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;
}

.footer {
    font-size: 14px;
    width: 1200px;
    height: 68px;
    background: #000;
    /* background: #2e58e9; */
    margin: 0 auto;
    padding: 25px;
    opacity: 0.5;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
}
</style>
